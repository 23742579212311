import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserOldService } from '../services/user_old.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  currentUser;
  items;
  itemNumbers;


  constructor(
    public router: Router,
    private user: UserOldService,) { }

  ngOnInit() {}

  goHome() {
    this.user.getUser().subscribe(res => {
      this.currentUser = res;
      this.router.navigateByUrl(`rewards/${this.currentUser.api_token}`);
    });
  }

  goToShoppingCart() {
    this.router.navigateByUrl('/shopping-cart');
  }

  goToNotifications() {
    this.router.navigateByUrl('/messages');
  }
}
//   openShoppingCart() {
//     this.router.navigate(['shopping-cart']);
//   }


//   openNotifications() {
//     this.router.navigate(['messages']);
//   }
// }
