import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';

@NgModule ({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class ComponentsModule {}
